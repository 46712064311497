import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { GetBioPictures } from "../hooks/get_bio_pictures.js"

import Layout from "../components/web/layout"
import SEO from "../components/web/seo"

import "./aboutus.css"

const AboutUs = () => {
  const images = GetBioPictures();
  return (
    <Layout>
      <SEO title="About Us" />
      <h2>Who We Are: </h2>
      <p> Alex and Jack Strang are brothers. They enjoy working on charismatic math problems together. 
        These passion projects have been the source of hours of (perhaps overly) enthusiastic conversation. 
        This website is home to the best of those conversations. </p>

      <p> To join the conversation email either <a href = "mailto: alex@mathmouth.com">alex@mathmouth.com</a> or <a href = "mailto: jack@mathmouth.com">jack@mathmouth.com</a> . </p>

      <div className="aboutus_bio_holder">
        <div className="aboutus_bio">
        <Img fixed={images.alex_small.childImageSharp.fixed} style={{float: 'left', marginRight: 10, borderRadius: 50}} />
        <p>
          <strong>Alex Strang </strong> is a instructor and an applied mathematician. He graduated from Case Western Reserve University this July with a PhD in applied mathematics and
          has a bachelor's in physics, mathematics, and music. He now works in the statistics department at the University of Chicago as a Kruskal instructor.
          His research includes optimization, theoretical ecology, stochastic processes, and Bayesian inverse problems.
          When he is not doing math, Alex is usually either writing music or running.
        </p>
        <p>
          Alex does most of the writing, and much of the math, for MathMouth. He proposes questions and works through the details they inspire.
        </p>
        <p>
          Orcid ID: <a href="https://orcid.org/0000-0001-6618-631X">https://orcid.org/0000-0001-6618-631X</a>
        </p>
        </div>
        <div className="aboutus_bio">
          <Img fixed={images.jack_small.childImageSharp.fixed} style={{float: 'left', marginRight: 10, borderRadius: 50}} />
          <p> 
            <strong>Jack Strang </strong> graduated from MIT in 2019 with a bachelors degree in computer science.
            He now lives in San Francisco and works as a software engineer on a machine learning team at GoDaddy.
            Previously, he built <a href = "//www.teachyourmachine.com">Teach Your Machine</a>, which walks the user
            through the machine learning process.  Jack spends his weekends skiing, hiking, and biking.
          </p>
          <p>
            Jack runs the technical side of MathMouth, builds the 
            interactive demos, and writes his share of the articles.
            He helps frame the content and find the forest in all the theoretical trees.
          </p>
        </div>
      </div>

      <br></br>
      <h2>Contributors: </h2>
      

      <Img fixed={images.jesse_small.childImageSharp.fixed} style={{float: 'left', marginRight: 10, borderRadius: 50}} />
      <p> 
      <strong>Jesse Ofsowitz </strong> studied mathematics and music at Case Western Reserve University and statistics at Penn State University. 
      He has been published in <i>Bartleby Snopes</i> and nominated for a Pushcart Prize. Visit his <a href = "//www.jesseofsowitz.com"> website </a> for more on English usage, 
      formatting, and style. He also writes yet unpublished postmodern novels.
      </p>
      <p>
      Jesse handles the editing for MathMouth.
      </p>

      <hr></hr>

      <Link to="/">Go back to the homepage</Link>
    </Layout>
  );
}

export default AboutUs
