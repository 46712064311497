import { useStaticQuery, graphql } from "gatsby"

export const GetBioPictures = () => {
  const images = useStaticQuery(graphql`
    query {
      alex: file(relativePath: { eq: "Alex_Running.jpg" }) {
        childImageSharp {
          fixed(width: 163, height: 297, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      alex_small: file(relativePath: { eq: "Alex_Bridge_Small.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      alex_tiny: file(relativePath: { eq: "Alex_Bridge_Small.jpg" }) {
        childImageSharp {
          fixed(width: 30, height: 30, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jack: file(relativePath: { eq: "Jack_Sand_Dunes.jpg" }) {
        childImageSharp {
          fixed(width: 163, height: 217, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jack_small: file(relativePath: { eq: "Jack_Sand_Dunes_Small.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jack_tiny: file(relativePath: { eq: "Jack_Sand_Dunes_Small.jpg" }) {
        childImageSharp {
          fixed(width: 30, height: 30, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jesse: file(relativePath: { eq: "Jesse_Camera_Small.jpg" }) {
        childImageSharp {
          fixed(width: 163, height: 297, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jesse_small: file(relativePath: { eq: "Jesse_Camera_Small.jpg" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jesse_tiny: file(relativePath: { eq: "Jesse_Camera_Small.jpg" }) {
        childImageSharp {
          fixed(width: 30, height: 30, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return images;
}